@media (min-width:992px) {
  .mobile-menu {
    display: none;
  }

  .menu-big-visible {
    opacity: 1 !important;
  }

  .position-relative.left-100 {
    right: 40% !important;
  }

  .team-content-section {
    min-height: 132px;
  }
}

@media (max-width: 991px) {

  ul.social-media-links,
  .z-index-1 {
    z-index: 1 !important;
  }

  .section-about article {
    top: 2rem !important;
    left: 1rem !important;
  }

  .section-about header {
    top: 0 !important;
  }

  .gap {
    padding: 24px !important;
  }

  .mobile-menu {
    position: relative;
    z-index: 100;
  }

  .nav-container .container {
    max-width: 100% !important;
    padding: 0;
  }

  .menu-list-container {
    padding: 1rem !important;
  }

  .menu-list-container .brand img {
    width: calc(100% / 2) !important
  }

  .banner-height {
    min-height: 415px;
  }

  .banner-heading span,
  .banner-heading {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 0 !important;
  }

  .headding-section h6 {
    margin-bottom: 1rem !important;
  }

  .menu-list {
    display: flex;
    flex-direction: column;
    background: var(--primaryBlue);
    position: fixed;
    width: 100%;
    left: -300%;
    bottom: 0;
    top: 0;
    right: 0;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }

  .menu-secandary-bg {
    display: flex;
    flex-direction: column;
    background: var(--bannerBg);
    position: fixed;
    width: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    align-items: center;
    justify-content: center;

  }

  .mobile-menu-flashing {
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }

  .menu-list ul {
    display: block !important;
    margin: auto !important;
    opacity: 0;
  }

  .menu-list ul li:first-child {
    padding-left: 20px !important;
  }

  .menu-list ul li {
    line-height: 40px;
  }

  li.active svg {
    display: none;
  }

  .opacity-5 {
    opacity: 0.25;
  }

  .sm-hidden {
    display: none;
  }

  .sm-visible {
    padding-left: 1rem !important;
    max-width: 100% !important;
    flex: auto;
  }

  .tab-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .client-list .swiper-slide {
    min-height: 100px !important;
  }

  .footer-copyrite {
    display: block !important;
  }

  .terms-condition a {
    margin-left: 0 !important;
  }

  .footer-copyrite {
    text-align: center;
  }

  .gap-8 {
    padding: 1rem !important;
  }

  .position-relative.left-100 {
    right: 40% !important;
  }

  .gap-margin {
    margin-top: 24px !important;
  }

  .modal-content-wrapper svg {
    width: 220px !important;
    margin-bottom: 1rem;
  }

  .flex-direction {
    flex-direction: column !important;
  }

  .flex-direction-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-direction header svg,
  .flex-direction-reverse header svg {
    width: 100% !important;
  }


  .clients.image-wrapper {
    position: absolute !important;
    top: 0;
    right: 0;
  }

  .form-content-wrapper {
    padding: 0;
    padding: 2rem !important;
    border-radius: 2rem !important;
  }

  .message-ico {
    margin-left: unset !important;
    position: absolute;
    right: 1rem;
  }

  .message-ico img {
    width: 48px;
  }
}

@media (max-width: 767px) {
  .banner-height {
    min-height: 300px;
  }

  .banner {
    padding: 0 !important;
  }

  .gap {
    padding: 16px !important;
  }

  section.section-home-vission-mission.gap {
    margin-top: 1.5rem;
  }

  .position-relative.left-100 {
    display: none !important;
  }

  .flex-direction header,
  .flex-direction-reverse header {
    margin-bottom: 1rem !important;
  }

  .flex-direction button,
  .flex-direction-reverse button {
    width: 100% !important;
  }

  .message-ico img {
    display: none;
  }

  .form-content-wrapper h2 {
    font-size: 1.6rem !important;
  }

  .form-content-wrapper .sm-banner-btn {
    width: 100% !important;
    margin: auto !important;
    display: block !important;
    margin-top: 1.5rem !important;
  }

  .image_wrapper,
  .solution_image {
    display: none;
  }

  .case_study_content h2 {
    font-size: 1.5rem;
    font-weight: 800;
    margin-top: 1rem;
    margin-bottom: 1rem !important;
  }
}