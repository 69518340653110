/* @import url('https://rsms.me/inter/inter.css'); */
@import url('../css/responsive.css');

:root {
  --bannerBg: #f7f8fd;
  --mid-darkGray: #646060;
  --darkGrey: #303030;
  --primaryBlue: #027fde;
  --secondaryBlue: #89cefb;
  --menuTextColor: rgba(0, 29, 53, 0.6);
  --normalText: 14px;
  --semiText: 16px;
  --headdingBig: 32px;
  --line-height: 24px;
  --vission-mission-bg: #f2faff;
}

html,
body,
* {
  font-family: 'Inter', sans-serif;
  margin: 0;
  color: var(--darkGrey);
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  text-rendering: optimizeLegibility;
  image-rendering: optimizeQuality;
  letter-spacing: 0.125px;
  font-size: var(--normalText);
  line-height: var(--line-height);
  scroll-behavior: smooth;
  font-feature-settings: 'ss04';
}

body.scroll-prevent {
  height: 100vh;
  overflow-y: hidden;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', 'system-ui';
  }
}

#myAnimate {
  position: relative;
  pointer-events: none;
  animation: rotate 8s ease-in-out infinite alternate;
}

@keyframes rotate {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  100% {
    transform: translateY(-500%);
  }
}

#myAnimate1 {
  position: relative;
  pointer-events: none;
  animation: rotate2 6s ease-in-out 0s infinite alternate none running;
}

@keyframes rotate2 {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0;
  }

  100% {
    transform: translateY(-600%) rotate(360deg);
    opacity: 1;
  }
}

#myAnimate2 {
  position: relative;
  pointer-events: none;
  animation: updown 8s ease-in-out infinite alternate;
}

@keyframes updown {
  0% {
    transform: translateX(0) rotate(0deg);
    opacity: 0;
  }

  100% {
    transform: translateX(-500%) rotate(360deg);
    opacity: 1;
  }
}

#myAnimate3 {
  position: relative;
  pointer-events: none;
  animation: leftRight 7s ease-in-out infinite alternate;
}

@keyframes leftRight {
  0% {
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }

  100% {
    transform: translateX(-250%) rotate(360deg);
    opacity: 0;
  }
}

#myAnimate4 {
  position: relative;
  pointer-events: none;
  animation: halfCircle 5s ease-in-out infinite alternate;
}

@keyframes halfCircle {
  100% {
    transform: translateY(-200%);
  }
}

#myAnimate5 {
  position: relative;
  pointer-events: none;
  animation: dottedGroup 8s infinite linear;
}

@keyframes dottedGroup {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }

  90% {
    opacity: 1;
  }

  100% {
    transform: translateX(-200px);
    opacity: 0;
  }
}

#myAnimate6 {
  position: relative;
  pointer-events: none;
  animation: robot 8s infinite linear alternate;
}

@keyframes robot {
  0% {
    transform: translateX(4%);
  }

  100% {
    transform: translateX(-4%);
  }
}

#imgAnimate {
  position: relative;
  pointer-events: none;
  animation: images 6s infinite linear alternate;
}

@keyframes images {
  0% {
    transform: translateY(4%);
  }

  100% {
    transform: translateY(-4%);
  }
}

span.guide-icon {
  width: 24px;
  height: 2px;
  background: rgba(0, 29, 53, 0.6);
  display: block;
  line-height: 1rem;
  margin: 4px;
}

.light-bg-primary {
  background-color: var(--bannerBg);
}

.z-top {
  top: 125px;
}

.right-0 {
  right: 0;
}

.button-primary {
  background-color: var(--secondaryBlue);
  height: 56px;
  line-height: calc(56px / 2);
  min-width: 220px;
  border-radius: 4px;
  font-size: var(--normalText);
  font-weight: 500;
  color: #000;
}

.button-primary-link {
  padding: 0;
  background-color: var(--secondaryBlue);
  line-height: 56px;
  min-width: 220px;
  border-radius: 4px;
  font-size: var(--normalText);
  font-weight: 500;
  color: #000;
}

.gap {
  padding-top: 100px;
}

.gap-bottom {
  padding-bottom: 100px;
  border-bottom: 1px solid #dfe1ea;
}

.gap-8 {
  padding-top: 80px;
}

.gap-4 {
  padding: 40px 0;
}

.sup {
  height: 1px;
  background: var(--darkGrey);
  width: 100%;
}

.headH2 {
  font-size: 22px;
  font-weight: 700;
  color: var(--darkGray);
  margin: 0;
  padding: 0;
}

.headH1 {
  font-size: 32px;
  font-weight: 700;
  color: var(--darkGray);
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
  margin-top: 30px;
}

.shapesIcons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: inherit;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
  /* visibility: hidden; */
}

.row-reverse {
  flex-direction: row-reverse;
}

.scroll-fix {
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.scroll-fix.active {
  background-color: rgba(247, 248, 253, 0.87);
  backdrop-filter: blur(10px);
  transition: all 0.3s;
  z-index: 1002;
}

.scroll-fix.active .menu-list-container {
  padding: 15px 0 !important;
  transition: all 0.3s ease;
}

.nav-container.light-bg-primary.active .menu-list-container {
  padding: 12px 0;
}

.scroll-to-top {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

button.scroll-to-top svg {
  width: 15px;
  height: 8px;
  fill: #495057;
}

.clients.scrolled.image-wrapper {
  position: absolute;
  transition: all 0.3s ease-in-out;
}

.image-wrapper img {
  pointer-events: none;
}

.img-about {
  scale: 1.3;
}

.banner {
  background-color: var(--bannerBg);
  padding-top: 110px;
}

.banner-img {
  position: inherit;
  z-index: 1;
  opacity: 0;
}

.banner-p {
  max-width: 306px;
}

.banner-heading {
  font-weight: 800 !important;
  color: var(--darkGrey);
  line-height: 39px;
  margin: 0;
  margin-bottom: 24px;
  font-size: 32px;
}

.banner-heading span {
  font-weight: 300;
  display: block;
  color: var(--primaryBlue) !important;
  font-size: 32px;
  line-height: 39px;
}

.smallBanner-wrapper {
  min-height: 450px;
  background-color: var(--bannerBg);
  position: relative;
  overflow: hidden;
  padding-top: 110px;
}

.z-index-1 {
  z-index: 100;
  position: relative;
}

.sm-banner-btn {
  min-width: 220px;
  height: 56px;
  border-radius: 6px;
  background-color: #fff;
  color: #646060;
  border-bottom: 1px solid #8994fb;
  position: relative;
  z-index: 1;
  box-shadow: 0px 8px 24px rgba(30, 30, 30, 0.08);
  font-size: 12px;
  transition: all 0.3s;
  padding: 0;
  line-height: 56px;
  opacity: 0;
}

.sm-banner-btn:focus {
  box-shadow: none;
  outline: none;
}

.position-relative.left-100 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 30%;
  position: absolute !important;
}

.image-wrapper {
  position: relative;
  opacity: 0;
}

.about.image-wrapper img,
.offerings.image-wrapper img {
  position: absolute;
  right: 0;
  top: 10px;
  transition: all 0.3s ease-in-out;
}

.blog.image-wrapper img {
  position: absolute;
  right: -68px;
  transition: all 0.3sease-in-out;
  top: -35px;
}

.clients.image-wrapper {
  position: fixed;
  top: 0;
  right: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
  min-height: 100%;
}

.swiper-pagination-bullet {
  width: 4px;
  height: 4px;
  background: #646060;
}

.swiper-pagination-bullet-active {
  background: #89cefb;
  width: var(--semiText);
  border-radius: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.headding-section {
  position: relative;
  z-index: 1;
}

.headding-section h6 {
  font-size: var(--semiText);
  color: var(--mid-darkGray) !important;
  font-weight: 600 !important;
  position: relative;
  margin: 0;
  margin-bottom: 24px;
}

.headding-section h6::after {
  content: '';
  width: 20px;
  height: 4px;
  position: absolute;
  background: var(--primaryBlue);
  top: 8px;
  margin-left: 8px;
}

.headding-section p {
  margin: 0;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: var(--normalText);
  color: var(--mid-darkGray);
}

ul.social-media-links {
  position: absolute;
  z-index: 1000;
  bottom: 24px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.abot-team .swiper-pagination-clickable {
  opacity: 0;
}

.social-media-links li {
  margin: 0;
  padding: 0;
  line-height: 24px;
  margin-right: 1rem;
}

.social-media-links li a {
  text-decoration: none;
  line-height: 24px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: #303030;
  letter-spacing: 0.6px;
  display: flex;
  align-items: center;
}

.social-media-links li a svg {
  margin-right: 6px;
}

.menu-list-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease-in-out;
}

.menu-list-container .brand img {
  width: 180px;
}

.menu-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  margin-left: auto;
  position: relative;
}

.menu-list ul li {
  padding: 0 20px;
  font-size: var(--normalText);
}

.menu-list ul li.active a {
  color: var(--primaryBlue);
  font-weight: 600;
}

li.active svg {
  position: absolute;
  top: -3px;
}

.menu-list ul li a {
  color: var(--menuTextColor);
  text-decoration: none;
}

.menu-list ul li:first-child {
  padding-left: 0;
}

.menu-list ul li:last-child {
  padding-right: 0;
}

/* ================ HOME ABOUT SECTION ================ */

.section-about header h2,
.section-about header h2 span {
  font-size: 22px;
  font-weight: 700;
  color: var(--mid-darkGray);
  margin: 0;
  padding: 0;
}

.section-about header {
  position: inherit;
  top: 1.15rem;
}

.section-about header h2 {
  position: relative;
  left: 1rem;
  top: 1rem;
}

.section-about header h2 span {
  display: block;
  color: var(--darkGrey);
}

.section-about header svg {
  position: absolute;
}

.after-blue {
  position: inherit;
  left: 1.1rem;
  top: 1.5rem;
}

.section-about img {
  pointer-events: none;
}

.section-about article {
  top: 4.5rem;
  position: inherit;
  left: 1rem;
}

.section-about article p {
  margin: 0;
  padding: 0;
}

/* ================ HOME VISSION MISSION SECTION ================ */
.vission-bg {
  background-color: var(--vission-mission-bg);
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.sm-hidden img {
  width: 100%;
}

.section-home-vission-mission header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 24px;
}

.vis-mis-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(59, 89, 152, 0.3);
  width: max-content;
  margin: auto;
  border-radius: 4px;
  padding: 8px;
}

.vis-mis-btn button {
  height: 40px;
  line-height: calc(40px / 2);
  min-width: 104px;
  font-weight: 500;
  border-radius: 4px;
}

.vis-mis-btn button:focus {
  box-shadow: none;
  outline: none;
}

.vis-mis-btn button.active {
  background: var(--secondaryBlue);
}

.tab-content {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.bg-proj-completed {
  background: #f7f8fd;
  padding-bottom: 60px;
}

.client-list .swiper-slide {
  min-height: 145px;
}

/* ================ PLATFORMS SECTION ================ */
.homeplatforms-section-bg {
  background: #f2faff;
  padding-top: 70px;
  padding-bottom: 30px;
  overflow: hidden;
}

.platforms-wrapper {
  padding-top: 40px;
}

.platforms-wrapper h2 {
  max-width: 264px;
  line-height: 27px;
  margin-bottom: 8px;
}

.platforms-wrapper p {
  max-width: 264px;
  font-size: var(--normalText);
}

.platforms-wrapper a {
  text-decoration: none;
  color: #303030;
  font-size: var(--normalText);
  font-weight: 500;
}

.platforms-wrapper .col-md-6 {
  padding-bottom: 40px;
  z-index: 10;
}

/* ================ FOOTER SECTION ================ */

.footer-social-media ul {
  position: relative !important;
  bottom: 0;
}

.footer-container img {
  pointer-events: none;
}

.footer-container h6 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  position: relative;
  top: calc(76px / 2);
  margin: 0;
  color: var(--darkGray);
}

.footer-container p {
  margin: 0;
  margin-top: 40px;
  margin-bottom: 40px;
}

.footer-logo {
  width: calc(253px / 1.3);
}

ul.usefull-links {
  position: relative;
  top: 35px;
  margin: 0;
  margin-top: 40px;
  padding: 0;
  list-style: none;
}

ul.usefull-links li {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
}

ul.usefull-links li a {
  color: var(--darkGrey);
}

.footer-sn {
  position: relative;
  top: 50px;
}

.footer-container form {
  position: inherit;
  top: 2.5rem;
}

.footer-container form input {
  height: 56px;
  border: none;
  background-color: #f7f8fd;
  padding: 0;
  padding: 1rem;
}

.footer-container form input:focus {
  box-shadow: none;
  border: none;
  background-color: #f7f8fd;
}

.form-container {
  position: relative;
}

.form-container button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  height: 56px;
  width: 66px;
  background-color: #89cefb;
}

.footer-copyrite {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.terms-condition a {
  color: var(--darkGrey);
  margin-left: 9.3rem;
}

/* ================ ABOUT SECTION ================ */

.team-wrapper article {
  margin-top: 30px;
  perspective: 100px;
}

.team-wrapper .row {
  transform-style: preserve-3d;
}

.content p {
  opacity: 0;
}

.opacity-0 {
  opacity: 0;
}

.point-events-none {
  pointer-events: none;
}

.team-img-section img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
}

.team-content-section {
  background-color: var(--bannerBg);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 1.5rem;
}

.team-content-section h6 {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 700;
}

.team-content-section p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
}

.team-content-section small {
  margin-bottom: 0.75rem;
  display: block;
}

.no-color img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.recent-wrapper h2 {
  margin: 0;
  margin-bottom: 40px;
}

.recent-wrapper img {
  pointer-events: none;
}

.rcc h6 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.rcc p {
  margin: 0;
  margin-bottom: 60px;
}

/* ================ OFFERINGS SECTION ================ */
.moving-bg {
  position: fixed;
  top: 0;
  width: 0%;
  background-color: var(--primaryBlue);
  z-index: 999;
  height: 100vh;
}

.blank-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bannerBg);
  z-index: 999;
  height: 100vh;
}

.moving-bg-light {
  background: var(--bannerBg);
  position: fixed;
  width: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  height: 100vh;
}

.modal-wrapper {
  position: relative;
  z-index: 1000;
  opacity: 0;
}

.modal-wrapper .back {
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 24px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
  margin-top: 1rem;
  margin-right: 1rem;
  transition: ease 0.4s;
  z-index: 999;
}

.modal-wrapper .back:hover {
  background: rgba(255, 255, 255, 1) !important;
}

.modal-wrapper .back button {
  color: var(--darkGray);
  text-decoration: none;
  font-size: 1rem !important;
}

.modal-content-wrapper {
  margin-top: 40px;
}

.modal-content-wrapper svg {
  width: 250px;
}

.modal-content-wrapper h2 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.modal-content-wrapper ul.main-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.modal-content-wrapper ul.main-list li {
  margin-bottom: 1.5rem;
  line-height: 1.5rem;
  font-size: var(--normalText);
}

.gap-margin {
  margin-top: 50px;
}

.card.gap-margin {
  border-radius: 20px;
  border: none;
  padding: 40px;
}

.card.gap-margin h4 {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  color: var(--primaryBlue);
  line-height: 1.5rem;
  margin-bottom: 8px;
}

.card-list li {
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: var(--mid-darkGray);
}

.products-card {
  background-color: #e5e5f1;
  width: 100%;
  border-radius: 20px;
  padding: 30px;
  margin-top: 50px;
  text-align: center;
}

.products-card h6 {
  font-size: 1rem;
  font-weight: 800;
  color: #616dd2;
}

.product-list {
  color: #646060;
  line-height: 26px;
}

/* ================ PLATFORMS SECTION ================ */

.platforms-content-wrapper header {
  margin-bottom: 1.5rem;
}

/* .platforms-content-wrapper header,
.p_content {

  opacity: 0;
} */

/* ================ CLIENTS SECTION ================ */
.cli-par-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #f7f8fd;
  width: 248px;
  padding: 4px;
  border-radius: 8px;
}

.cli-par-btn button {
  min-width: 120px;
  height: 48px;
  color: #606060;
  font-weight: 700;
}

.cli-par-btn button:focus {
  outline: none;
  box-shadow: none;
}

.cli-par-btn button.active {
  background-color: #fff;
}

.tab-content img {
  pointer-events: none;
}

img.img-fluid.img-height-fix {
  width: 320px;
}

/* ================ CONTACT SECTION ================ */
.form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(0, 123, 255, 0.5) !important;
}

.form-content-wrapper {
  background: #f7f8fd;
  border-radius: 60px;
  padding: 90px 0;
  opacity: 0;
}

.form-content-wrapper h6 {
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
  color: var(--menuTextColor);
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.form-content-wrapper h2 {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 32px;
  font-weight: 700;
}

.form-content-wrapper p {
  margin: 0;
  color: var(--menuTextColor);
  margin-bottom: 1.5rem;
}

#form h6 {
  margin-top: 36px;
  font-weight: 700;
  color: #303030;
}

#form p {
  color: var(--menuTextColor);
  font-size: var(--normalText);
  margin-bottom: 1rem;
}

#form .pe {
  color: var(--menuTextColor);
  font-size: var(--normalText);
}

#form .pe:last-child {
  margin-bottom: 1.5rem !important;
}

#form .pe:last-child {
  margin-bottom: 1rem;
}

#form .form-control:not(textarea) {
  height: 56px;
  border: 1px solid rgba(0, 29, 53, 0.08);
}

#form textarea {
  border: 1px solid rgba(0, 29, 53, 0.08);
  padding: 1rem;
}

#form span {
  font-size: 12px;
  color: crimson;
  display: none;
  padding: 3px;
}

#form input:invalid[focused='true'],
#form textarea:invalid[focused='true'] {
  border: 1px solid salmon;
}

#form input:invalid[focused='true']~span,
#form textarea:invalid[focused='true']~span {
  display: block;
}

.our-locaiton-wrapper h5 {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 1.5rem;
}

.our-locaiton-wrapper p,
.our-locaiton-wrapper .pe {
  color: var(--menuTextColor);
}

.our-locaiton-wrapper .pe:last-child {
  margin-bottom: 1rem;
}

/* ================ CASE STUDY DETAILS SECTION ================ */

.case_study_content h2 {
  font-size: 1.5rem;
  font-weight: 700;
}

.case_study_content ul {
  list-style: none;
}

.case_study_content ul li::before {
  content: '»';
  color: #007bff;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.sec_background {
  margin-top: 4rem;
}

.case_header svg {
  position: absolute;
}

.case_header h2 {
  position: relative;
  top: 0.5rem;
  left: 1rem;
}

.icon svg {
  opacity: 0.1;
}