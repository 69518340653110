.blog-section {
  position: relative;
}

.blog-section header {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -30%);
  font-size: 22px;
  font-weight: bold;
  line-height: 1.25;
  margin: 0;
}

.blog-section h1 {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -30%);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.25;
  margin: 0;
  margin-bottom: 1.5rem;
}

.blog-section header::after {
  position: absolute;
  content: "";
  display: block;
  width: 20px;
  height: 4px;
  background-color: #027fde;
  z-index: 100;
  margin-top: 20px;
  border-radius: 16px;
}

.platforms-content-wrapper .p_content {
  margin-top: 4rem;
  font-size: 14px;
  color: #303030;
  font-weight: 400;
}

.date-section {
  font-size: 12px;
  font-weight: 600;
  color: #696464;
  /* text-align: end; */
}

.blog-img {
  border-radius: 4px;
}

.button-primary-blog {
  padding: 0;
  background-color: var(--secondaryBlue);
  line-height: 40px;
  min-width: 180px;
  border-radius: 4px;
  font-size: var(--normalText);
  font-weight: 500;
  color: #000;
}

.blog_wrapper {
  padding: 1.5rem;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(33, 33, 52, 0.1);
  border-radius: 6px;
}

.blog_list p:nth-child(1) {
  max-width: calc(100% - 56px);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail-img {
  width: 100%;
  height: 250px;
  object-fit: scale-down;
  object-position: left;
  display: block;
}