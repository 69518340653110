.p-sidebar-right .p-sidebar {
  width: calc(100vw - 10rem);
  background-color: rgba(255, 255, 255, 0.89);
  backdrop-filter: blur(10px);
}

.p-sidebar-left .p-sidebar {
  width: 100%;
}

.jobs__wrapper .header {
  display: flex;
  justify-content: space-between;
  background: #e8e9ec;
  padding: 0.5rem 1rem;
  font-weight: 700
}

.jobs__wrapper .content {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid #e8e9ec;
}

.jobs__wrapper .header div {
  min-width: calc(100% / 4);
}

.jobs__wrapper .content div {
  font-size: 13px;
  color: #303030;
  min-width: calc(100% / 4);
  padding: 0 3px;
}

.jobs-opening h4 {
  font-weight: 700;
}

.jobApply__wrapper button {
  min-width: 96px;
}

.jobdetails__content__wrapper ul {
  list-style: square;
  margin: 0;

}

.jobdetails__content__wrapper li {
  list-style: square;
  margin: 0;
  padding: 0;
}



@media (min-width:768px) {
  .visible-xs {
    display: none;
  }
}

@media (max-width:767px) {
  .hide-xs {
    display: none;
  }

  .p-sidebar-right .p-sidebar {
    width: calc(100%);
  }

  .container.jobs-opening {
    padding: 0;
  }

  .jobApply__wrapper {
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
    padding: 1rem;
    background-color: #fff;
  }

  .jobApply__wrapper .ml-auto {
    margin-left: 0;
    margin: auto !important;
  }

  .jobdetails__wrapper h1 {
    font-size: 24px;
  }

  .jobdetails__content__wrapper {
    height: calc(100vh - 135px);
    overflow: hidden;
    overflow-y: auto;
  }
}